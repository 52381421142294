<template>
  <div>
    <div class="row">
      <div class="col d-flex justify-content-between">
        <vs-breadcrumb
          :items="breadcrumbs"
        ></vs-breadcrumb>
        <div>
          <vs-button icon="add" @click="open = true" type="gradient">Crear nuevo</vs-button>
        </div>

      </div>
    </div>

    <draggable @change="updatePositionBanner" v-model="banners">
      <vs-card style="max-width: 300px; display: inline-block; margin: 15px; cursor: pointer" v-for="(banner, index) in banners" :key="index" class="cardx">
        <div slot="media">
          <img :src="banner.src">
        </div>
        <div>
          <h5>{{banner.title}}</h5>
          <h6>Mostrar botón: {{banner.show_button ? 'Si' : 'No'}}</h6>
          <a :href="banner.url_button" target="_blank" v-if="banner.show_button">Abrir Url</a>
          <h6>Posicion: {{banner.position}}</h6>
        </div>
        <div slot="footer">
          <vs-row vs-justify="flex-end">
            <vs-button icon="create" @click="editBanner(banner)" type="gradient" color="primary">Editar</vs-button>
            <vs-button icon="delete_sweep" @click="confirmDelete(banner.id)" class="ml-2" type="gradient" color="danger">Eliminar</vs-button>
          </vs-row>
        </div>
      </vs-card>
    </draggable>

    <vs-popup classContent="popup-example" :title="edit ? 'Editar banner' : 'Registrar banner'" :active.sync="open">
      <vs-textarea placeholder="Titulo del banner" v-model="banner.title" />
      <div class="row">
        <div class="col">
          <label for="">Color del titulo</label> <br>
          <input type="color" v-model="banner.color_title">
        </div>
        <div class="col">
          <label for="">Mostrar botón en el banner</label>
          <vs-switch v-model="banner.show_button"></vs-switch>
          <vs-input v-model="banner.url_button" v-if="banner.show_button" class="inputx mt-2" placeholder="Url a donde enviara el botón"/>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-6">
          <div class="button-wrapper">
            <span class="label">
              {{file_name}}
            </span>
            <input data-vv-as="Banner" name="banner" v-validate="'required'" @change="handleFile" type="file" id="upload" class="upload-box" placeholder="Subir archivo">
          </div>
          <span v-show="errors.has('banner')" class="w-100 text-danger mt-2">
            {{ errors.first("banner") }}
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col text-right">
          <vs-button icon="save" :disabled="loading" v-if="edit" class="mt-5" @click="updateBanner" color="primary" type="gradient">Editar</vs-button>
          <vs-button icon="save" :disabled="loading" v-else class="mt-5" @click="storeBanner" color="primary" type="gradient">Guardar</vs-button>
        </div>
      </div>

    </vs-popup>

  </div>
</template>

<script>
import bannersService from '@/services/banners.service'
import draggable from 'vuedraggable'
export default {
  components: {
    draggable,
  },
  data () {
    return {
      loading: false,
      file_name: 'Seleccionar imagen',
      valid: false,
      error: false,
      open: false,
      edit: false,

      banner: {
        title: '',
        color_title: '#000000',
        image: '',
        show_button: false,
        url_button: ''
      },
      banners: [],
      breadcrumbs: [
        {
          title: 'Admin Tag Store',
          url: '/'
        },
        {
          title: 'Banners',
          active: true
        }
      ]
    }
  },
  watch: {
    open(newVal) {
      if (!newVal) {
        this.edit = false
        this.banner = JSON.parse(JSON.stringify(this.$options.data().banner))
      }
    }
  },
  methods: {
    async updatePositionBanner() {

      const new_positions = []

      this.banners.forEach((i, index) => {
        new_positions.push({
          id: i.id,
          position: index + 1,
          name: i.name
        })
      })
      const { data:res } = await bannersService.updatePositionBanner({
        positions: new_positions
      })
      if (res.success) {
        this.$vs.notify({
          title: res.message,
          text:'',
          color: 'success',
          position: 'top-right'
        })
        this.getBanners()
      }
    },
    async getBanners() {
      this.$vs.loading({
        type: 'point'
      })
      const { data:res } = await bannersService.getBanners()
      if (res.success) {
        this.banners = res.data.data
      } else {
        this.error = true
      }
      this.$vs.loading.close()
    },
    async storeBanner() {
      this.$validator.validateAll().then(async result => {
        try {
          this.loading = true
          const formData = new FormData();
          formData.append('title', this.banner.title)
          formData.append('image', this.banner.image)
          formData.append('color_title', this.banner.color_title)
          formData.append('show_button', this.banner.show_button ? true : false)
          formData.append('url_button', this.banner.url_button)

          const { data:res } = await bannersService.storeBanner(formData)

          if (res.success) {
            this.open = false
            this.banners.push(res.data)
            this.$vs.notify({
              title: res.message,
              text:'',
              color: 'success',
              position: 'top-right'
            })
          }

        } catch(e) {
          console.log(e)
        }
        this.loading = false
      })
    },
    editBanner(item) {
      this.banner = JSON.parse(JSON.stringify(item))
      this.open = true
      this.edit = true
    },
    async updateBanner() {
      try {
        this.loading = true
        const formData = new FormData();
        formData.append('id', this.banner.id)
        formData.append('title', this.banner.title)
        formData.append('image', this.banner.image)
        formData.append('color_title', this.banner.color_title)
        formData.append('show_button', this.banner.show_button ? true : false)
        formData.append('url_button', this.banner.url_button)
        formData.append('_method', 'PUT')

        const { data:res } = await bannersService.updateBanner(formData)

        if (res.success) {
          const index = this.banners.findIndex(i => i.id == res.data.id)
          this.banners[index] = res.data
          this.open = false
          this.banners = JSON.parse(JSON.stringify(this.banners))
          this.$vs.notify({
            title: res.message,
            text:'',
            color: 'success',
            position: 'top-right'
          })
        } else {
          console.log(res)
        }
      } catch(e) {
        console.log(e)
      }
      this.loading = false
    },
    confirmDelete(id) {
      this.$confirm(
        {
          message: `Seguro de eliminar el banner?`,
          button: {
            no: 'No',
            yes: 'Si'
          },
          callback: async confirm => {
            if (confirm) {
              const { data:res } = await bannersService.deleteBanner({
                id: id
              })
              if (res.success) {
                const index = this.banners.findIndex(i => i.id == res.data.id)
                this.banners.splice(index, 1)
              } else {
                console.log(res)
              }
            }
          }
        }
      )
    },
    handleFile(event) {
      this.file_name = event.target.files[0].name
      this.banner.image = event.target.files[0]
    }
  },
  created() {
    this.getBanners()
  }
}
</script>
