import api from "../api"

class BannersService {

	static getBanners() {
		return api({
			method: 'get',
			url: 'banners'
		})
	}

	static storeBanner(data) {
		return api({
			method: 'post',
			data: data,
			url: 'banners'
		})
	}

	static updateBanner(data) {
		return api({
			method: 'post',
			data: data,
			url: 'banners'
		})
	}

	static updatePositionBanner(data) {
		return api({
			method: 'put',
			data: data,
			url: 'update-position-banner'
		})
	}

	static deleteBanner(data) {
		return api({
			method: 'delete',
			data: data,
			url: 'banners'
		})
	}

}

export default BannersService
